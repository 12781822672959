import React from "react";
import { NavLink } from "react-router-dom";

import NavBar from "components/shared/NavBar";

import oTemplateXl from 'assets/images/png/template-xl.png';
import oTemplateMd from 'assets/images/png/template-md.png';
import oTemplateDarkXl from 'assets/images/png/template-xl-dark.png';
import oTemplateDarkMd from 'assets/images/png/template-md-dark.png';

const Header = () => {

	return (
		<section className="overflow-hidden">
			<NavBar />
			<section id="home" className="bg-primary-500 dark:bg-primary-dark h-screen w-screen flex items-center justify-center md:justify-start before:h-44 xl:before:h-60 before:bg-dark before:bottom-0">
				<div className="container ml-0 md:pl-10 mt-10 md:mt-0 z-10 w-full lg:w-2/4">
					<div className="text-center md:text-left">
						<h1 className="z-20 uppercase text-white dark:text-gray-100 font-sans font-extrabold text-xl md:text-4xl">Mantén el control de tu</h1>
						<h2 className="z-20 uppercase text-white dark:text-gray-100 font-sans font-black text-5xl md:text-8xl bg-opacity-50">cervecería</h2>
						<p className="mt-5 text-white dark:text-gray-100 text-base md:text-xl mx-auto md:mx-0 tracking-tight w-4/5">
							Controla tu elaboración, maneja tu mercancía con la seguridad y la certeza que BrewControl te ofrece
						</p>
						<NavLink to="/register">
							<button className="px-5 py-3 rounded-xl shadow-2xl text-white dark:text-gray-100 font-bold uppercase mt-10 duration-1000 hover:scale-110 cursor-pointer bg-gray-800 dark:bg-primary-800">
								Contratar 🍻
							</button>
						</NavLink>
					</div>
				</div>
				<div className="hidden md:block w-2/4 pr-0 xl:pr-10">
					<img alt="Muestra panel de administración" src={oTemplateXl} className='hidden xl:block w-full dark:hidden shadow-lg' />
					<img alt="Muestra panel de administración" src={oTemplateMd} className='hidden lg:block xl:hidden w-3/4 mx-auto mr-0 dark:hidden shadow-lg' />
					<img alt="Muestra panel de administración" src={oTemplateDarkXl} className='hidden dark:xl:block w-full shadow-lg' />
					<img alt="Muestra panel de administración" src={oTemplateDarkMd} className='hidden dark:lg:block dark:xl:hidden w-3/4 mx-auto mr-0 shadow-lg' />
				</div>
				<div className="absolute -bottom-32 h-32 bg-white dark:bg-dark -z-10 w-full" />
			</section>
		</section>
	);
};

export default Header;
