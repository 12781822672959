import SystemContextProvider from 'context/SystemContext';
import Layout from 'pages/Layout';

function App() {
  return <SystemContextProvider>
    <Layout />
  </SystemContextProvider>;
}

export default App;
