import React from "react";
import { LANDING_CONSTANTS } from "config/landing.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowDown, faFileContract, faMobileScreenButton, faPenClip } from "@fortawesome/free-solid-svg-icons";

const Steps = () => {

    const sTitleClass = 'font-medium text-gray-800 dark:text-gray-200 mb-1 text-xl'

    return <section id={LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.STEPS} className="bg-white dark:bg-dark">
        <div className="container px-5 py-12 mx-auto flex flex-wrap">
            <div className="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 dark:bg-gray-500 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-primary-500 dark:bg-primary-dark text-white relative z-10 title-font font-medium text-sm">1</div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                    <div className="flex-shrink-0 w-24 h-24 bg-primary-100 text-primary-500 rounded-full inline-flex items-center justify-center shadow-lg">
                        <FontAwesomeIcon icon={faPenClip} className="w-10 h-10" />
                    </div>
                    <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                        <h2 className={sTitleClass}>Registrate</h2>
                        <p className="text-styles">
                            Registrate ahora mismo, no tiene costo y se te solicitará únicamente información básica
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 dark:bg-gray-500 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-primary-500 dark:bg-primary-dark text-white relative z-10 title-font font-medium text-sm">2</div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                    <div className="flex-shrink-0 w-24 h-24 bg-primary-100 text-primary-500 rounded-full inline-flex items-center justify-center shadow-lg">
                        <FontAwesomeIcon icon={faCloudArrowDown} className="w-10 h-10" />
                    </div>
                    <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                        <h2 className={sTitleClass}>Descarga nuestra aplicación</h2>
                        <p className="text-styles">
                            Podrás encontrar nuestras APP's en las tiendas de aplicaciones para tu dispositivo
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 dark:bg-gray-500 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-primary-500 dark:bg-primary-dark text-white relative z-10 title-font font-medium text-sm">3</div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                    <div className="flex-shrink-0 w-24 h-24 bg-primary-100 text-primary-500 rounded-full inline-flex items-center justify-center shadow-lg">
                        <FontAwesomeIcon icon={faMobileScreenButton} className="w-10 h-10" />
                    </div>
                    <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                        <h2 className={sTitleClass}>Comienza el trackeo</h2>
                        <p className="text-styles">
                            Descarga los códigos QR's y pegalos en tus barriles, escanealos y que comience el trackeo!
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
                    <div className="h-full w-1 bg-gray-200 dark:bg-gray-500 pointer-events-none"></div>
                </div>
                <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-primary-500 dark:bg-primary-dark text-white relative z-10 title-font font-medium text-sm">4</div>
                <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                    <div className="flex-shrink-0 w-24 h-24 bg-primary-100 text-primary-500 rounded-full inline-flex items-center justify-center shadow-lg">
                        <FontAwesomeIcon icon={faFileContract} className="w-10 h-10" />
                    </div>
                    <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
                        <h2 className={sTitleClass}>Aumenta tus barriles</h2>
                        <p className="text-styles">
                            Contamos con los planes mas accesibles del mercado y siempre
                            puedes contactarnos en caso de que quieras un plan personalizado
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>;
}

export default Steps;