import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faChevronDown } from "@fortawesome/free-solid-svg-icons";

const ComboBox = ({
  onChange = null,
  options: aData = [],
  label: sLabel = null,
  selected: oSelected = null,
  className: sClassName = null,
  labelClass: sCustomLabelClass = null,
  buttonClassName: sCustomButtonClass = null,
  emptyLabel: sEmptySelectionLabel = "Seleccione una opción",
}) => {
  const sButtonClass = "relative w-full border-input cursor-pointer bg-white dark:bg-gray-800 py-2 pl-3 pr-10 text-left sm:text-sm rounded-md";
  const sOptionContainerClass = "absolute w-full mt-1 max-h-60 overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg sm:text-sm";
  const sOptionClass = "relative cursor-pointer select-none py-2 pl-10 pr-4 z-50";
  const sOptionActiveClass = "bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-400";
  const sOptionInactiveClass = "text-gray-800 dark:text-gray-400";

  return <div className={sClassName || null}>
    {
      sLabel &&
      <span className={sCustomLabelClass || "text-gray-700 dark:text-white font-semibold mb-1"}>
        {sLabel}
      </span>
    }
    <Listbox value={oSelected} onChange={oItem => onChange && onChange(oItem)}>
      <div className="relative mt-1">
        <Listbox.Button className={`${sButtonClass} ${sCustomButtonClass}`}>
          <span className="block truncate text-gray-800 dark:text-white mt-1">{oSelected?.name || sEmptySelectionLabel}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4 text-gray-600 dark:text-gray-400" />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className={sOptionContainerClass}>
            {
              aData.map((oItem, nIndex) => (
                <Listbox.Option
                  key={nIndex}
                  value={oItem}
                  className={({ active: bActive }) => (sOptionClass + " " + (bActive ? sOptionActiveClass : sOptionInactiveClass))}
                >
                  {({ selected: oSelectedItem }) => (
                    <>
                      <span className={`block truncate ${oSelectedItem ? "font-medium" : "font-normal"}`}>
                        {oItem.name}
                      </span>
                      {
                        oSelectedItem
                          ? <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                            <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-gray-600 dark:text-gray-400" />
                          </span>
                          : null
                      }
                    </>
                  )}
                </Listbox.Option>
              ))
            }
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  </div>;
}

export default ComboBox;
