import React from "react";
import { useNavigate } from "react-router-dom";

const FrequencyQuestions = () => {
  const navigate = useNavigate();

  const onNavigate = () => navigate('/faq');

  return <section className="flex justify-center text-center text-gray-600 dark:text-gray-300 bg-white dark:bg-dark pb-12">
    <div className="text-sm tracking-widest md:hover:tracking-[0.3rem] cursor-pointer w-fit">
      <p onClick={onNavigate}>¿Alguna duda?</p>
      <p onClick={onNavigate} className="font-semibold">Visite nuestras preguntas frecuentes</p>
    </div>
  </section>
}

export default FrequencyQuestions;