import React from "react";

import Contact from "components/landing/Contact";
import Features from "components/landing/Features";
import Footer from "components/landing/Footer";
import Header from "components/landing/Header";
import Pricing from "components/landing/Pricing";
import Steps from "components/landing/Steps";
import FrequencyQuestions from "components/landing/FrequencyQuestions";

export default function Landing() {

  return <>
    <Header />
    <Features />
    <Steps />
    <Pricing />
    <Contact />
    <FrequencyQuestions />
    <Footer />
  </>;
}
