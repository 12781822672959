export const LANDING_CONSTANTS = {

  SECTIONS_IDENTIFIERS: {
    HOME: 'home',
    PLANS: 'plans',
    CONTACT: 'contact',
    FEATURES: 'features',
    STEPS: 'steps',
    PATTERNS: 'patterns',
    FAQ: 'faq',
  },

  CONTACT_INFORMATION: {
    PHONE: '+598 93 610 408',
    EMAIL: 'marketing@brewcontrol.uy',
    ADDRESS: 'Puntas de Valdez, San José, Uruguay',
  }

};
