import React, { Suspense, useContext } from "react";
import { BrowserRouter } from "react-router-dom";

import { SystemContext } from "context/SystemContext";
import { RouteConfig } from "routes/Router";

export default function Layout() {
  const { bDarkTheme } = useContext(SystemContext);

  return <main className={bDarkTheme ? "dark" : ""}>
    <BrowserRouter>
      <Suspense>
        <RouteConfig />
      </Suspense>
    </BrowserRouter>
  </main>;
}
