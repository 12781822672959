import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { LANDING_CONSTANTS } from "config/landing.config";

const Contact = () => {
    const [sName, setName] = React.useState('');
    const [sEmail, setEmail] = React.useState('');
    const [sMessage, setMessage] = React.useState('');
    const [bNameError, setNameError] = React.useState(false);
    const [bEmailError, setEmailError] = React.useState(false);
    const [bMessageError, setMessageError] = React.useState(false);
    const [sMessageError, setMessageErrorText] = React.useState('');
    const [sMessageSuccess, setMessageSuccess] = React.useState('');
    const [bLoading, setLoading] = React.useState(false);

    const sInputClassName = "w-full rounded border text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out";
    const sInputCorrect = "border-gray-300 focus:border-primary-500 dark:focus:border-primary-dark focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-dark";

    const onSubmit = async () => {
        onClean();
        const oData = {
            name: sName.trim(),
            email: sEmail.trim(),
            message: sMessage.trim()
        };
        if (!oData.name) {
            setNameError(true);
            return setMessageErrorText("Nombre requerido");
        }
        if (!oData.email) {
            setEmailError(true);
            return setMessageErrorText("Email requerido");
        }
        const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!EMAIL_REGEX.test(oData.email.toLowerCase())) {
            setEmailError(true);
            return setMessageErrorText("Formato de email incorrecto");
        }
        if (!oData.message) {
            setMessageError(true);
            return setMessageErrorText("Mensaje requerido");
        }
        try {
            setLoading(true);
            const oSuccess = await axios.post(`${process.env.REACT_APP_API_URL}contact`, { data: oData });
            onClean(true);
            setLoading(false);
            setMessageSuccess(oSuccess?.data?.message || 'Mensaje enviado, responderemos a la brevedad');
        } catch (error) {
            setLoading(false);
            setMessageErrorText(error?.response?.data?.message || "Error al enviar mensaje");
        }
    }

    const onClean = (bForm = false) => {
        setMessageSuccess('');
        setNameError(false);
        setEmailError(false);
        setMessageError(false);
        setMessageErrorText('');
        if (bForm) {
            setName('');
            setEmail('');
            setMessage('');
        }
    }

    return <section className="text-gray-600 relative bg-white dark:bg-dark pb-12 md:py-12" id={LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.CONTACT}>
        <div className="container px-5 py-12 mx-auto flex md:flex-nowrap flex-wrap">
            <div className="lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative md:order-1 img-background">
                <div className="bg-white relative flex flex-wrap py-6 rounded shadow-md">
                    <div className="lg:w-1/2 px-6">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs uppercase">Dirección</h2>
                        <p className="mt-1">{LANDING_CONSTANTS.CONTACT_INFORMATION.ADDRESS}</p>
                    </div>
                    <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs uppercase">Email</h2>
                        <a href={`mailto:${LANDING_CONSTANTS.CONTACT_INFORMATION.EMAIL}`} className="leading-relaxed">{LANDING_CONSTANTS.CONTACT_INFORMATION.EMAIL}</a>
                        <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs uppercase mt-4">Teléfono</h2>
                        <p className="leading-relaxed">{LANDING_CONSTANTS.CONTACT_INFORMATION.PHONE}</p>
                    </div>
                </div>
            </div>
            <div className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0 order-1 md:order-2">
                <h2 className="text-xl mb-1 font-bold text-gray-800 dark:text-gray-300">Contáctenos</h2>
                <p className="leading-relaxed mb-5 text-styles">Déjenos sus comentarios y nos pondremos en contacto con usted a la brevedad</p>
                <div className="relative mb-4">
                    <label htmlFor="name" className={`leading-7 text-sm ${bNameError ? "text-red-500" : "text-styles"}`}>Nombre</label>
                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={sName}
                        onChange={e => setName(e.target.value)}
                        className={`${bNameError ? "border-red-500" : sInputCorrect} ${sInputClassName}`}
                        placeholder="Nombre completo"
                    />
                </div>
                <div className="relative mb-4">
                    <label htmlFor="email" className={`leading-7 text-sm ${bEmailError ? "text-red-500" : "text-styles"}`}>Email</label>
                    <input
                        id="email"
                        name="email"
                        type="email"
                        value={sEmail}
                        onChange={e => setEmail(e.target.value)}
                        className={`${bEmailError ? "border-red-500" : sInputCorrect} ${sInputClassName}`}
                        placeholder="Correo electrónico" />
                </div>
                <div className="relative mb-4">
                    <label htmlFor="message" className={`leading-7 text-sm ${bMessageError ? "text-red-500" : "text-styles"}`}>Mensaje</label>
                    <textarea
                        id="message"
                        name="message"
                        value={sMessage}
                        onChange={e => setMessage(e.target.value)}
                        placeholder="Escriba su consulta/sugerencía aquí"
                        className={`${bMessageError ? "border-red-500" : sInputCorrect} ${sInputClassName} h-32 resize-none`}
                    >
                        {sMessage}
                    </textarea>
                </div>
                {sMessageError && <div className="text-red-500 text-xs text-center mb-2">{sMessageError}</div>}
                {sMessageSuccess && <div className="text-green-500 text-xs text-center mb-2">{sMessageSuccess}</div>}
                <button
                    onClick={onSubmit}
                    className="text-white bg-primary-500 dark:bg-primary-dark border-0 py-2 px-6 focus:outline-none hover:bg-primary-dark rounded text-base">
                    {
                        bLoading ?
                            <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
                            :
                            'Enviar mensaje'
                    }
                </button>
                <p className="text-xs text-gray-500 mt-3 text-center">Le responderemos a la brevedad</p>
            </div>
        </div>
    </section>
}

export default Contact;