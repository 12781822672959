import React from "react";
import { LANDING_CONSTANTS } from "config/landing.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faChalkboardTeacher, faCloud, faHeadset, faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";

const Features = () => {

  const sTitleClass = 'text-lg text-gray-800 dark:text-gray-200 font-medium title-font ml-2 h-full';
  const sContentClass = 'border border-gray-200 p-6 rounded-lg h-full shadow-lg hover:shadow-md';
  const sIconClass = 'w-10 h-10 inline-flex items-center justify-center rounded-full bg-primary-100 dark:bg-primary-300 text-primary-500 dark:text-primary-dark';

  return <section className="bg-white dark:bg-dark" id={LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.FEATURES}>
    <div className="container px-5 pb-12 pt-5 mx-auto">
      <div className="flex flex-wrap w-full mb-20 flex-col items-center text-center">
        <h1 className="landing-title z-10">Características</h1>
        <p className="landing-subtitle z-10">
          Nuestro enfoque se basa en agilizar su trabajo sin perder
          de vista la seguridad y confianza que necesita su cervecería
        </p>
      </div>
      <div className="flex flex-wrap -m-4">
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <FontAwesomeIcon icon={faChalkboardTeacher} className='w-6 h-6' />
              </div>
              <h2 className={sTitleClass}>Fácil de usar</h2>
            </div>
            <p className="text-styles tracking-wide">
              Nuestro equipo se compone por cerveceros, por lo que procuramos
              que nuestro sistema sea intuitivo y fácil de usar
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <FontAwesomeIcon icon={faMobileScreenButton} className='w-6 h-6' />
              </div>
              <h2 className={sTitleClass}>Multi dispositivo</h2>
            </div>
            <p className="text-styles tracking-wide">
              Nuestro panel administrativo es accesible desde cualquier dispositivo inteligente,
              además nuestra aplicación lo mantendrá informado en todo momento
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <FontAwesomeIcon icon={faCloud} className="w-6 h-6" />
              </div>
              <h2 className={sTitleClass}>Alcance</h2>
            </div>
            <p className="text-styles tracking-wider">
              Compruebe el estado de su cervecería desde cualquier parte
              del mundo con solo tener acceso a internet
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <svg fill="none" stroke="currentColor"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="w-6 h-6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <h2 className={sTitleClass}>Tecnologías de punta</h2>
            </div>
            <p className="text-styles tracking-wide">
              Nuestros sistemas fueron desarrollados con las tecnologías que son
              tendencias en el mercado de software actual
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <FontAwesomeIcon icon={faHeadset} className="w-5 h-5" />
              </div>
              <h2 className={sTitleClass}>Soporte</h2>
            </div>
            <p className="text-styles tracking-tight">
              Nuestros contratos contienen una vía de comunicación directa con
              nuestro equipo de soporte, cualquier duda o inconveniente será atendido como se debe
            </p>
          </div>
        </div>
        <div className="xl:w-1/3 md:w-1/2 p-4">
          <div className={sContentClass}>
            <div className="flex flex-row items-center mb-4">
              <div className={sIconClass}>
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-6 h-6" viewBox="0 0 24 24">
                  <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                </svg>
              </div>
              <h2 className={sTitleClass}>Seguridad</h2>
            </div>
            <p className="text-styles tracking-wide">
              Sus datos son nuestra prioridad, por lo que nos aseguramos de manejar
              todos sus datos sencibles con la debida precaución
            </p>
          </div>
        </div>
      </div>
      <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.CONTACT}`} className="flex items-center mx-auto mt-16 text-white bg-primary-500 dark:bg-primary-dark hover:bg-primary-800 dark:hover:bg-primary-500 border-0 py-2 px-8 focus:outline-none rounded uppercase w-min">
        Contactar
        <FontAwesomeIcon icon={faArrowRight} className='ml-3' />
      </a>
    </div>
  </section>;
}

export default Features;
