import React from "react";

export const INPUT_TYPE = {
    DEFAULT: 'text',
    NUMBER: 'number',
    EMAIL: 'email',
    PASSWORD: 'password',
    PHONE: 'tel',
};

const Input = ({
    value: sValue,
    onBlur = null,
    onFocus = null,
    onKeyUp = null,
    onSubmit = null,
    onChange = null,
    label: sLabel = null,
    id: sIdentifier = null,
    setRef: onSetRef = null,
    disabled: bDisabled = false,
    className: sClassName = null,
    placeholder: sPlaceHolder = '',
    type: sType = INPUT_TYPE.DEFAULT,
    withoutClass: bWithOutClass = false,
}) => {

    if (!sValue)
        sValue = '';

    const aClassName = [
        'text-gray-800 dark:text-white',
        'rounded-md',
        'px-2',
        'py-2',
        'bg-white dark:bg-gray-800',
        'mt-1',
    ];

    if (sClassName)
        aClassName.push(sClassName);

    return <div className="flex flex-col">
        <span className="text-gray-700 dark:text-white font-semibold mb-1">{sLabel}</span>
        <input
            type={sType}
            ref={onSetRef}
            onBlur={onBlur}
            id={sIdentifier}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            onSubmit={onSubmit}
            value={sValue || ''}
            disabled={bDisabled}
            className={bWithOutClass ? sClassName : aClassName.join(' ')}
            placeholder={sPlaceHolder}
            onChange={oEvent => onChange ? onChange(oEvent.target.value, sIdentifier) : null}
        />
    </div>;
}

export default Input;
