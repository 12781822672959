import React, { Fragment, useState } from "react";
import {
  Accordion as AccordionMaterial,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const Accordion = ({ title, description, titleClassName = '', ...props }) => {
  const [open, setOpen] = useState(false);

  return <Fragment>
    <AccordionMaterial open={open} {...props}>
      <AccordionHeader className={titleClassName} onClick={() => setOpen(!open)}>{title}</AccordionHeader>
      <AccordionBody>
        <div className="text-base" dangerouslySetInnerHTML={{ __html: description }} />
      </AccordionBody>
    </AccordionMaterial>
  </Fragment>;
}

export default Accordion;