import React, { useEffect } from "react";

import Footer from "components/landing/Footer";
import NavBar from "components/shared/NavBar";
import Accordion from "components/shared/Accordion";

const faqs = [
  {
    title: "¿Cómo puedo crear una cuenta?",
    description: "<p>Para crear una cuenta puedes hacerlo desde nuestra página web <a class='underline' href='https://brewcontrol.uy/register'>aquí</a> o directamente desde nuestra aplicación</p>",
  },
  {
    title: "¿Donde encuentro BrewControl?",
    description: "<p>Puede encontrar nuestra aplicación tanto en <a class='underline' href='https://play.google.com/store/apps/details?id=com.brew_control' target='_blank'>Google Play</a> como en <a class='underline' href='https://apps.apple.com/us/app/brew-control/id1628660126' target='_blank'>Apple Store</a>, también nos encontramos en versión WEB en nuestro <a class='underline' href='https://admin.brewcontrol.uy/' target='_blank'>Panel Administrativo</a></p>",
  },
  {
    title: "¿Que es el panel administrativo?",
    description: "<p>El panel administrativo, es una versión web de nuestra aplicación, pero en este caso, podrás ver temas mas administrativos y tendrás una visualización del sistema mas amplia que desde la aplicación, puedes acceder desde <a class='underline' href='https://admin.brewcontrol.uy/' target='_blank'>aquí</a></p>",
  },
  {
    title: "¿Donde veo mis barriles?",
    description: "<p>Tus barriles puedes verlos desde nuestra APP en la sección barriles (Menú desplegable -> Barriles) allí puedes ver todos tus barriles, separados por su estado, también podrás filtrarlos facilmente</p>",
  },
  {
    title: "¿Donde imprimo mis QR's?",
    description: "<p>Los QR's solo se pueden imprimir desde nuestro panel administrativo (en la sección barriles/fermentadores), podrás imprimir nuevos o de lo contrario puedes seleccionar barriles/fermentadores de la lista y re imprimir viejos QR's</p>",
  },
  {
    title: "¿Como solicito nuevos QR's?",
    description: `<p>Nuevamente, desde nuestro panel administrativo, debes ir a la sección barriles o fermentadores, encontrarás un botón "Nuevo" con el cual podrás solicitar nuevos QR's, ten en cuenta que no puedes exceder el límite de tu plan`,
  },
  {
    title: "¿Para que puedo utilizar el fermentador en la aplicación?",
    description: "<p>Los fermentadores son utilizados en la aplicación para manejar mas eficasmente la trasa de su producto, al momento de asignarle una cerveza a un barril, se puede asociar directamente el fermentador y así, a partir de un fermentador, saber a que clientes llegó ese lote</p>",
  },
  {
    title: "¿Que sucede luego de que pasan los 90 días de cuenta FREE?",
    description: "<p>Antes de que venza este plazo, debes ir a Mi Perfil dentro de la aplicación y seleccionar el plan que mejor se adapte a tus necesidades, puedes acceder a un descuento en nuestros planes abonando de forma anual</p>",
  },
  {
    title: "¿Que sucede si se me daña o rompe un QR?",
    description: "<p>Nada! Solo ingresa al panel administrativo, dirijete a la sección barriles, busca el barril al que se le daño el QR e imprimelo nuevamente</p>",
  },
  {
    title: "¿Como puedo comunicarme con ustedes?",
    description: `<p>Puedes comunicarte con nosotros a través de <a class='underline' href="https://api.whatsapp.com/send?phone=+59893610408&amp;text=Hola! Quisiera saber mas acerca de BrewControl!" target="_blank">Whatsapp</a>, llamada teléfonica o mensaje al número +598 93 610 408 o de lo contrario puede contactarnos directamente de nuesta página web o enviandonos un email a <a class='underline' href="mailto:admin@brewcontrol.uy?subject=Contacto%20BrewControl" target="_blank">admin@brewcontrol.uy</a></p>`,
  },
  {
    title: "¿Como migro mis datos de otra APP?",
    description: "<p>Para migrar sus datos desde otro sistema a BrewControl, debe ponerse en contacto con nosotros y a partir de ahí evaluaremos la situación en particular</p>",
  },
]

export default function Faq() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return <>
    <NavBar />
    <section id="home" className="pt-20 bg-primary-500 dark:bg-primary-dark flex items-center justify-center before:bottom-0">
      <h2 className="py-52 w-full z-20 uppercase text-white dark:text-gray-100 font-sans font-black text-3xl md:text-6xl bg-opacity-50 text-center">Preguntas frecuentes</h2>
    </section>
    <section className="flex flex-col px-5 md:px-20 my-10 gap-5">
      {faqs.map((item, index) => <Accordion
        key={index}
        title={item.title}
        description={item.description}
        className="border-[1px] rounded-md px-5"
        titleClassName="border-b-2 border-primary-500 my-3 py-2"
      />
      )}
    </section>
    <Footer />
  </>;
}
