import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Landing from "pages/Landing";
import Register from "pages/Register";
import Policy from "pages/Policy";
import Faq from "pages/Faq";

const aRoutes = [
  { key: 'landing', path: '/', element: <Landing /> },
  { key: 'register', path: '/register', element: <Register /> },
  { key: "faq", path: "/faq", element: <Faq /> },
  { key: "policy", path: "/policy", element: <Policy /> },
  // TODO: Fix into APP
  { key: "policity", path: "/policity", element: <Policy /> },
  { key: "notFound", path: "*", element: <Landing /> },
];

export function RouteConfig() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) window.gtag('send', 'pageview', location.pathname);
  }, [location]);

  return <Routes>
    {aRoutes.map(oRoute => <Route {...oRoute} />)}
  </Routes>;
}
