import React, { createRef, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import ScrollSpy from "react-scrollspy-navigation";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Toggle from "components/shared/Toggle";

import { SystemContext } from "context/SystemContext";
import { LANDING_CONSTANTS } from "config/landing.config";

import oLogo from 'assets/images/png/logo.png';

const NavBar = () => {
  const { bDarkTheme, onChangeTheme } = useContext(SystemContext);
  const oNavigation = useNavigate();

  const [sHeaderBackground, setHeaderBackground] = useState("");
  const [sIconsColors, setIconColors] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY <= 120) {
      setIconColors('text-gray-800 dark:text-gray-300');
      return setHeaderBackground("");
    }
    setIconColors('text-gray-200');
    return setHeaderBackground("bg-black bg-opacity-80");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const onScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const onNavigate = () => {
    if (window.location.pathname === '/') return onScrollToTop();
    oNavigation('/');
  }

  const renderChangeTheme = () => (<div className="flex gap-1 justify-center py-3">
    <svg xmlns="http://www.w3.org/2000/svg" className={`${sIconsColors} ${isOpen && "text-gray-200"}`} width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="12" r="5"></circle>
      <line x1="12" y1="1" x2="12" y2="3"></line>
      <line x1="12" y1="21" x2="12" y2="23"></line>
      <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
      <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
      <line x1="1" y1="12" x2="3" y2="12"></line>
      <line x1="21" y1="12" x2="23" y2="12"></line>
      <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
      <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
    </svg>
    <Toggle checked={bDarkTheme} onChange={onChangeTheme} />
    <svg xmlns="http://www.w3.org/2000/svg" className={`${sIconsColors} ${isOpen && "text-gray-200"}`} width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
    </svg>
  </div>);

  return <header className="fixed top-0 z-50 w-full">
    <nav className={`${sHeaderBackground} text-white dark:text-gray-100 transition-all duration-1000 ${isOpen && "bg-black bg-opacity-80"}`}>
      <div className="max-w-7xl mx-auto px-4 py-3 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <button onClick={onNavigate} className="flex flex-[10] title-font font-medium items-center text-white dark:text-gray-100 mb-0">
            <img src={oLogo} className="w-14 text-white dark:text-gray-100 p-2 bg-primary-500 dark:bg-primary-dark rounded-full" alt="BrewControl Logo" />
            <span className="ml-3 text-xl text-white dark:text-gray-100 font-bold">BrewControl</span>
          </button>
          <div className="hidden md:block m-auto">
            <div className="flex items-center self-center w-full md:space-x-3 lg:space-x-10 mr-0">
              <ScrollSpy className={"active-item"}>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.HOME}`} className="nav-item" ref={createRef()}>Inicio</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.FEATURES}`} className="nav-item" ref={createRef()}>Características</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.STEPS}`} className="nav-item" ref={createRef()}>¿Como inicio?</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.PLANS}`} className="nav-item" ref={createRef()}>Planes</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.CONTACT}`} className="nav-item" ref={createRef()}>Contáctenos</a>
              </ScrollSpy>
              {renderChangeTheme()}
              <a href="https://admin.brewcontrol.uy" rel="noreferrer" className="dark:bg-primary-800 bg-gray-800 px-4 py-2 rounded nav-item dark:text-gray-100">
                Admin
              </a>
            </div>
          </div>
          <div className="flex md:hidden ml-auto flex-[2]">
            <button
              type="button"
              onClick={() => setIsOpen(!isOpen)}
              className="bg-transparent inline-flex items-center justify-center px-3 py-2 rounded-md text-white"
              aria-controls="mobile-menu" aria-expanded="false"
            >
              {
                !isOpen
                  ? <FontAwesomeIcon icon={faBars} className="block h-5 w-5" />
                  : <FontAwesomeIcon className="block h-5 w-5" icon={faX} />
              }
            </button>
          </div>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {(ref) => (
          <div className="md:hidden" id="mobile-menu">
            <div ref={ref} className="px-2 pt-2 pb-3 space-y-3 sm:px-3 text-center">
              <ScrollSpy className={"active-item"}>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.HOME}`} ref={createRef()} className="py-2 nav-item block ">Inicio</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.FEATURES}`} ref={createRef()} className="py-2 nav-item block ">Características</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.STEPS}`} ref={createRef()} className="py-2 nav-item block ">¿Como inicio?</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.PLANS}`} ref={createRef()} className="py-2 nav-item block ">Planes</a>
                <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.CONTACT}`} ref={createRef()} className="py-2 nav-item block ">Contáctenos</a>
              </ScrollSpy>
              {renderChangeTheme()}
              <a href="https://admin.brewcontrol.uy" rel="noreferrer" className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0">
                Admin
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  </header>;
}

export default NavBar;