import React from "react";
import PropsTypes from "prop-types";
import { Switch } from "@headlessui/react";

function Toggle({
  checked: bChecked = false,
  disabled: bDisabled = false,
  onChange,
  uncheckedStyles: sUncheckedStyles = "bg-primary-800"
}) {

  return <Switch
    disabled={onChange ? bDisabled : true}
    checked={bChecked}
    onChange={onChange ? onChange : () => { }}
    className={`${bChecked ? "bg-primary-500" : sUncheckedStyles} relative inline-flex items-center h-4 rounded-full w-8 border-[.5px] border-dark dark:border-white ${bChecked ? 'justify-end' : 'justify-start'}`}
  >
    <span className={`pointer-events-none inline-block h-[18px] w-[18px] transform bg-white rounded-full`} />
  </Switch>;
}

Toggle.propTypes = {
  checked: PropsTypes.bool,
  disabled: PropsTypes.bool,
  onChange: PropsTypes.func,
  uncheckedStyles: PropsTypes.string,
};

export default Toggle;
