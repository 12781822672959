import React, { createRef } from "react";
import ScrollSpy from "react-scrollspy-navigation/dist/ScrollSpy";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LANDING_CONSTANTS } from "config/landing.config";

import oLogo from 'assets/images/png/logo.png';

const Footer = () => {

  return <footer>
    <div className="container px-5 py-12 md:py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
      <div className="flex-grow flex flex-wrap md:pr-20 md:text-left">
        <div className="w-full text-center md:text-left md:w-1/2 px-4">
          <h2 className="title-styles text-sm mb-3 uppercase font-bold">Menú</h2>
          <section className="grid grid-cols-1 gap-1">
            <ScrollSpy>
              <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.HOME}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Inicio</a>
              <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.FEATURES}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Características</a>
              <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.STEPS}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Registrarme</a>
              <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.PLANS}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Planes</a>
              <a href={`#${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.CONTACT}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Contáctenos</a>
              <a href={`/${LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.FAQ}`} className="uppercase text-sm text-dark dark:text-gray-400" ref={createRef()}>Preguntas frecuentes</a>
            </ScrollSpy>
          </section>
        </div>
        <div className="w-full text-center md:text-left mt-10 md:mt-0 md:w-1/2 px-4">
          <h2 className="title-styles text-sm mb-3 uppercase font-bold">Contacto</h2>
          <section className="grid grid-cols-1 gap-1">
            <span className="text-dark dark:text-gray-400"><FontAwesomeIcon icon={faPhone} /> {LANDING_CONSTANTS.CONTACT_INFORMATION.PHONE}</span>
            <span rel="noreferrer" target='_blank' href={`mailto:${LANDING_CONSTANTS.CONTACT_INFORMATION.EMAIL}`} className="text-dark dark:text-gray-400"><FontAwesomeIcon icon={faEnvelope} /> {LANDING_CONSTANTS.CONTACT_INFORMATION.EMAIL}</span>
            <span className="text-dark dark:text-gray-400">Puntas de Valdez, San José</span>
            <span className="text-dark dark:text-gray-400">Uruguay 🇺🇾</span>
          </section>
        </div>
      </div>
      <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
        <span className="flex title-styles items-center md:justify-start justify-center">
          <img src={oLogo} className="w-14 text-white p-2 bg-primary-500 dark:bg-primary-dark rounded-full" alt="BrewControl Logo" />
          <span className="ml-3 text-xl dark:text-white">BrewControl</span>
        </span>
        <p className="mt-2 text-sm text-gray-500">Aplicación de gestión cervecera</p>
      </div>
    </div>
    <div className="bg-gray-300 dark:bg-gray-900">
      <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
        <p className="text-gray-500 text-sm text-center sm:text-left">© 2022 BrewControl —
          <a href="https://leandrocurbelo.uy" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">Leandro Curbelo</a>
        </p>
        <div className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
          <a rel="noreferrer" target='_blank' href="https://www.facebook.com/Brew-Control-102422732512073" className="ml-3 text-gray-500 cursor-pointer">
            <svg fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a rel="noreferrer" target='_blank' href={`https://api.whatsapp.com/send?phone=${LANDING_CONSTANTS.CONTACT_INFORMATION.PHONE}&text=Hola! Quería consultarles acerca de los planes de BrewControl!`} className="ml-3 text-gray-500 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5"
              viewBox="0 0 16 16">
              <path
                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
            </svg>
          </a>
          <a rel="noreferrer" target='_blank' href="https://instagram.com/brewcontrol_app" className="ml-3 text-gray-500 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" fill="gray" className="w-5 h-5"
              viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </footer>;
};

export default Footer;
