import React, { useState } from "react";

export const SystemContext = React.createContext({});

export default function SystemContextProvider({ children }) {
    const [bDarkTheme, setDarkTheme] = useState(false);

    const onChangeTheme = () => {
        setDarkTheme(bTheme => !bTheme);
    };

    return <SystemContext.Provider
        value={{ bDarkTheme, onChangeTheme }}>
        {children}
    </SystemContext.Provider>;
}

