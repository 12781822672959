import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Input, { INPUT_TYPE } from "components/shared/Input";
import ComboBox from "components/shared/ComboBox";

import oLogo from 'assets/images/png/logo.png';

export default function Register() {

  const [sCompanyName, setCompanyName] = useState("");
  const [sName, setName] = useState("");
  const [sEmail, setEmail] = useState("");
  const [sPhone, setPhone] = useState("");
  const [sPassword, setPassword] = useState("");
  const [sConfirmPassword, setConfirmPassword] = useState("");
  const [oCountry, setCountry] = useState(null);

  const [bLoading, setLoading] = useState(false);
  const [sMessageError, setMessageError] = useState("");
  const [sMessageSuccess, setMessageSuccess] = useState("");

  const [bNameError, setNameError] = useState(false);
  const [bCompanyNameError, setCompanyNameError] = useState(false);
  const [bEmailError, setEmailError] = useState(false);
  const [bPhoneError, setPhoneError] = useState(false);
  const [bPasswordError, setPasswordError] = useState(false);
  const [bConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [bCountryError, setCountryError] = useState(false);

  const [aCountries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const oResponse = await axios.get(`${process.env.REACT_APP_API_URL}app/countries`);
        setCountries(oResponse.data?.data || null);
      } catch (error) {
        setCountries(null);
      }
    }
    getCountries();
  }, []);

  const onSubmit = async () => {
    onClean();
    const oData = {
      company_name: sCompanyName.trim(),
      name: sName.trim(),
      email: sEmail.trim(),
      phone: sPhone.trim(),
      password: sPassword.trim(),
      id_country: oCountry?.id || null,
    };
    let bError = false;
    let bFormatError = false;
    let bConfirmError = false;
    if (!oData.company_name) {
      setCompanyNameError(true);
      bError = true;
    }
    if (!oData.name) {
      setNameError(true);
      bError = true;
    }
    if (!oData.email) {
      setEmailError(true);
      bError = true;
    }
    const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!EMAIL_REGEX.test(oData.email.toLowerCase())) {
      setEmailError(true);
      bFormatError = true;
    }
    if (!oData.id_country) {
      setCountryError(true);
      bError = true;
    }
    if (!oData.password) {
      setPasswordError(true);
      bError = true;
    }
    if (!sConfirmPassword.trim()) {
      setConfirmPasswordError(true);
      bError = true;
    }
    if (oData.password !== sConfirmPassword.trim()) {
      setConfirmPasswordError(true);
      bConfirmError = true;
    }
    if (bError)
      return setMessageError("Complete todos los campos");
    if (bFormatError)
      return setMessageError("Formato de email incorrecto");
    if (bConfirmError)
      return setMessageError("Las contraseñas no coinciden");
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}register`, { data: oData });
      onClean(true);
      setLoading(false);
      if (window.gtag) window.gtag('send', 'register', oData);
      setMessageSuccess('Cuenta creada, verifique su casilla de correo electrónico');
    } catch (error) {
      setLoading(false);
      setMessageError(error?.response?.data?.message || "Error al enviar formulario");
    }
  }

  const onClean = (bForm = false) => {
    setCompanyNameError(false);
    setNameError(false);
    setEmailError(false);
    setPhoneError(false);
    setCountryError(false);
    setPasswordError(false);
    setConfirmPasswordError(false);
    setMessageError("")
    setMessageSuccess("")
    if (bForm) {
      setCompanyName("");
      setName("");
      setEmail("");
      setPhone("");
      setPassword("");
      setConfirmPassword("");
      setCountry(null);
    }
  }

  const sInputCorrect = "border-[1.5px] border-red-500";

  const getClassName = (bError) => {
    return `mb-3 focus:border-primary-500 dark:focus:border-primary-dark focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-dark transition-all duration-75 ${bError ? sInputCorrect : ""}`;
  }

  return (
    <main className="bg-primary-500 dark:bg-primary-dark min-h-screen">
      <div className="md:grid md:grid-cols-2 lg:grid-cols-6 p-10 md:p-20">
        <section className="text-center mb-10 md:mb-0 lg:col-span-2 flex items-center">
          <div className="m-auto">
            <img src={oLogo} className="w-28 p-3 bg-white dark:bg-dark rounded-full m-auto" alt="BrewControl Logo" />
            <h2 className="mt-5 uppercase text-white dark:text-gray-100 font-sans font-black text-3xl tracking-wide">Registro</h2>
            <p className="mt-5 text-white dark:text-gray-100 font-semibold md:px-10">
              Cree una cuenta en BrewControl para obtener acceso a todos los servicios que ofrecemos.
            </p>
          </div>
        </section>
        <section className="col-span-1 lg:col-span-4">
          <div className="bg-gray-50 dark:bg-dark rounded-lg shadow-lg p-5 md:p-8">
            <Input
              id="companyName"
              value={sCompanyName}
              onChange={setCompanyName}
              placeholder="Brew Control"
              label="Nombre de la empresa cervecera"
              className={getClassName(bCompanyNameError)}
            />
            <Input
              id="name"
              value={sName}
              onChange={setName}
              placeholder="John Doe"
              label="Nombre responsable"
              className={getClassName(bNameError)}
            />
            <Input
              id="email"
              label="Email"
              value={sEmail}
              onChange={setEmail}
              type={INPUT_TYPE.EMAIL}
              placeholder="john@brewcontrol.uy"
              className={getClassName(bEmailError)}
            />
            <Input
              id="phone"
              value={sPhone}
              label="Teléfono"
              onChange={setPhone}
              type={INPUT_TYPE.PHONE}
              placeholder="0912345678"
              className={getClassName(bPhoneError)}
            />
            <ComboBox
              selected={oCountry}
              options={aCountries || []}
              onChange={setCountry}
              label="Seleccione un país"
              emptyLabel="Seleccione un país"
              buttonClassName={getClassName(bCountryError)}
            />
            <Input
              id="password"
              value={sPassword}
              label="Contraseña"
              onChange={setPassword}
              placeholder="**********"
              type={INPUT_TYPE.PASSWORD}
              className={getClassName(bPasswordError)}
            />
            <Input
              id="confirmPassword"
              placeholder="**********"
              value={sConfirmPassword}
              type={INPUT_TYPE.PASSWORD}
              label="Confirmar Contraseña"
              onChange={setConfirmPassword}
              className={getClassName(bConfirmPasswordError)}
            />
            {
              sMessageError &&
              <p className="text-red-500 font-bold text-center text-sm">
                {sMessageError}
              </p>
            }
            {
              sMessageSuccess &&
              <p className="text-green-500 font-bold text-center text-sm">
                {sMessageSuccess}
              </p>
            }
            <button
              onClick={onSubmit}
              className="text-white bg-primary-500 dark:bg-primary-dark border-0 mt-3 py-2 px-6 focus:outline-none hover:bg-primary-dark rounded text-base w-full">
              {
                bLoading ?
                  <>
                    Creando cuenta <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" />
                  </>
                  :
                  'Crear cuenta 🍻'
              }
            </button>
          </div>
        </section>
      </div>

    </main>
  );
}
