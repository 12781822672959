import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";

import { LANDING_CONSTANTS } from "config/landing.config";

const Pricing = () => {

  const [bAnnual, setAnnual] = useState(false);

  const [bHoverFree, setHoverFree] = useState(false);
  const [bHoverBronze, setHoverBronze] = useState(false);
  const [bHoverSilver, setHoverSilver] = useState(false);
  const [bHoverGold, setHoverGold] = useState(false);

  const onChangeAnnual = () => {
    setAnnual(bValue => !bValue);
  }

  return <section className="overflow-hidden bg-white dark:text-gray-300 dark:bg-dark" id={LANDING_CONSTANTS.SECTIONS_IDENTIFIERS.PLANS}>
    <div className="container px-5 mx-auto">
      <div className="flex flex-col text-center w-full pt-12 pb-6">
        <h1 className="landing-title">Nuestros planes</h1>
        <p className="landing-subtitle">
          Nuestros planes son los mas accesibles del mercado, siempre puedes
          contactarnos para obtener más información o preguntar por planes
          que se adapten mejor a tu situación
        </p>
        <div className="flex mx-auto border-2 border-primary-500 dark:border-primary-dark rounded overflow-hidden mt-6">
          <button onClick={onChangeAnnual} className={`${!bAnnual ? "bg-primary-500 dark:bg-primary-dark text-white" : "text-gray-400"} py-1 px-4 focus:outline-none`}>Mensual</button>
          <button onClick={onChangeAnnual} className={`${bAnnual ? "bg-primary-500 dark:bg-primary-dark text-white" : "text-gray-400"} py-1 px-4 focus:outline-none`}>Anual</button>
        </div>
      </div>
      <div className="flex flex-wrap -m-4 py-6">
        <div className={`pricing-container ${bHoverFree ? 'pricing-hover' : ''}`}>
          <div className="pricing-content border-gray-300 dark:border-gray-400" onMouseEnter={() => setHoverFree(true)} onMouseLeave={() => setHoverFree(false)}>
            <h2 className="text-xs tracking-widest font-semibold mb-1 text-primary-800 bg-primary-100 w-min px-2 py-1 rounded-full">FREE</h2>
            <h1 className="pricing-price-container pricing-price text-gray-700">Gratis</h1>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>20 Barriles
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>2 Fermentadores
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>2 Usuarios
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>5 Clientes
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>10 Estilos de cerveza
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>1000 Facturas
            </p>
            <p className="flex items-center text-styles mb-6">
              <span className="pricing-still-icon bg-red-500">
                <FontAwesomeIcon icon={faX} className="w-2 h-2" />
              </span>Soporte
            </p>
            <NavLink to="/register">
              <button className="pricing-button">Contratar
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </NavLink>
            <p className="text-xs text-gray-500 mt-3">Pruebalo ahora mismo sin costo 🎉</p>
          </div>
        </div>
        <div className={`pricing-container ${bHoverBronze ? 'pricing-hover' : ''}`}>
          <div className="pricing-content border-gray-300 dark:border-gray-400" onMouseEnter={() => setHoverBronze(true)} onMouseLeave={() => setHoverBronze(false)}>
            <div className="grid grid-cols-2">
              <h2 className="text-xs tracking-widest font-semibold mb-1 text-primary-800 bg-primary-100 w-min px-2 py-1 rounded-full">BRONCE</h2>
              {bAnnual && <h2 className="text-xs text-right tracking-widest mb-1 text-primary-800 px-2 py-1 rounded-full">Ahorras 10%</h2>}
            </div>
            <h1 className="pricing-price-container">
              {
                bAnnual
                  ? <>
                    <span className="pricing-price text-gray-700">USD 86</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/año</span>
                  </>
                  : <>
                    <span className="pricing-price text-gray-700">USD 8</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/mes</span>
                  </>
              }
            </h1>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Hasta 150 barriles
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Fermentadores
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Usuarios
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Clientes
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Estilos de cerveza
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Facturas
            </p>
            <p className="flex items-center text-styles mb-6">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Soporte
            </p>
            <NavLink to="/register">
              <button className="pricing-button">Contratar
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </NavLink>
            <p className="text-xs text-gray-500 mt-3">Mejora tu gestión ahora mismo</p>
          </div>
        </div>
        <div className={`pricing-container ${bHoverSilver ? 'pricing-hover' : ''}`}>
          <div className="pricing-content pricing-content-popular border-gray-500 dark:border-gray-200" onMouseEnter={() => setHoverSilver(true)} onMouseLeave={() => setHoverSilver(false)}>
            <span className="pricing-popular-label">POPULAR</span>
            <div className="grid grid-cols-2">
              <h2 className="text-xs tracking-widest font-semibold mb-1 text-primary-800 bg-primary-100 w-min px-2 py-1 rounded-full">PLATA</h2>
              {bAnnual && <h2 className="text-xs text-right tracking-widest mb-1 text-primary-800 px-2 py-1 rounded-full">Ahorras 10%</h2>}
            </div>
            <h1 className="pricing-price-container">
              {
                bAnnual
                  ? <>
                    <span className="pricing-price text-gray-700">USD 162</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/año</span>
                  </>
                  : <>
                    <span className="pricing-price text-gray-700">USD 15</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/mes</span>
                  </>
              }
            </h1>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Hasta 300 barriles
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Fermentadores
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Usuarios
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Clientes
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Estilos de cerveza
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Facturas
            </p>
            <p className="flex items-center text-styles mb-6">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Soporte
            </p>
            <NavLink to="/register">
              <button className="pricing-button">Contratar
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </NavLink>
            <p className="text-xs text-gray-500 mt-3">El plan más elegido por nuestros clientes</p>
          </div>
        </div>
        <div className={`pricing-container ${bHoverGold ? 'pricing-hover' : ''}`}>
          <div className="pricing-content border-gray-300 dark:border-gray-400" onMouseEnter={() => setHoverGold(true)} onMouseLeave={() => setHoverGold(false)}>
            <div className="grid grid-cols-2">
              <h2 className="text-xs tracking-widest font-semibold mb-1 text-primary-800 bg-primary-100 w-min px-2 py-1 rounded-full">ORO</h2>
              {bAnnual && <h2 className="text-xs text-right tracking-widest mb-1 text-primary-800 px-2 py-1 rounded-full">Ahorras 10%</h2>}
            </div>
            <h1 className="pricing-price-container">
              {
                bAnnual
                  ? <>
                    <span className="pricing-price text-gray-700">USD 270</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/año</span>
                  </>
                  : <>
                    <span className="pricing-price text-gray-700">USD 25</span>
                    <span className="text-lg ml-1 font-normal text-gray-500">/mes</span>
                  </>
              }
            </h1>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Hasta 600 barriles
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Fermentadores
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Usuarios
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Clientes
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Estilos de cerveza
            </p>
            <p className="flex items-center text-styles mb-2">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>∞ Facturas
            </p>
            <p className="flex items-center text-styles mb-6">
              <span className="pricing-still-icon bg-green-500">
                <FontAwesomeIcon icon={faCheck} className='w-3 h-3' />
              </span>Soporte
            </p>
            <NavLink to="/register">
              <button className="pricing-button">Contratar
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            </NavLink>
            <p className="text-xs text-gray-500 mt-3">Para cerveceros de otro nivel 🚀</p>
          </div>
        </div>
      </div>
      <section className="flex flex-col items-center">
        <p className="text-center animate-bounce text-lg text-gray-800 dark:text-white my-12">
          ¡Si buscas más opciones no dudes en contactarnos!
        </p>
        <div className="flex flex-col md:flex-row py-6 space-y-5 md:space-y-0 md:space-x-5">
          <a target="_blank" href="https://play.google.com/store/apps/details?id=com.brew_control" rel="noreferrer" className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 text-gray-600 dark:text-gray-800" viewBox="0 0 512 512">
              <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
            </svg>
            <span className="ml-4 flex items-start flex-col leading-none">
              <span className="text-xs mb-1 text-gray-500 dark:text-gray-800">DESCARGAR EN</span>
              <span className="title-font font-medium text-gray-600 dark:text-gray-800">Google Play</span>
            </span>
          </a>
          <a target="_blank" href="https://apps.apple.com/us/app/brew-control/id1628660126" rel="noreferrer" className="bg-gray-100 inline-flex py-3 px-5 rounded-lg items-center hover:bg-gray-300 focus:outline-none shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-6 h-6 text-gray-600 dark:text-gray-800" viewBox="0 0 305 305">
              <path d="M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z"></path>
              <path d="M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z"></path>
            </svg>
            <span className="ml-4 flex items-start flex-col leading-none">
              <span className="text-xs mb-1 text-gray-500 dark:text-gray-800">DESCARGAR EN</span>
              <span className="title-font font-medium text-gray-600 dark:text-gray-800">App Store</span>
            </span>
          </a>
        </div>
      </section>
    </div>
  </section>
}

export default Pricing;